.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.container {
  max-width: 500px;
  background-color: white;
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  font-weight: 600;
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

label {
  font-weight: 500;
  display: block;
  margin-bottom: 0.5rem;
}

input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
}

input[type="text"]:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

button {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.result-container {
  margin-top: 2rem;
}

.result-container h3 {
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  text-align: center;
}

.generated-image {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
